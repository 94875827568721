/**
 * Converts els into an array.
 *
 * @function makeArray
 * @param {(Array.<HtmlElement>|HtmlElement)} els
 * @returns {Array.<HtmlElement>}
 */
function makeArray (els) {
  if (els === undefined || els === null) {
    return []
  }

  // Some elements can be iterable, for example forms and selects. We
  // specifically check whether els is a DOM node.
  let arr
  if (els.nodeType) {
    arr = [els]
  } else {
    arr = Array.from(els)
  }

  if (!arr.length && els && els.length === undefined) {
    arr = [els]
  }

  return arr
}

/**
 * Calls fn for each element in els
 *
 * @function forEach
 * @param {(Array.<HtmlElement>|HtmlElement)} els
 * @param {function} fn
 */
function forEach (els, fn) {
  const arr = makeArray(els)
  arr.forEach(fn)
}

/**
 * Finds each ancestor of any of els which matches selector. Enforces
 * uniqueness.
 *
 * @function find
 * @param {(Array.<HtmlElement>|HtmlElement)} els
 * @param {string} selector
 * @returns {Array.<HtmlElement>}
 */
function find (els, selector) {
  const rv = new Set()
  forEach(els, el => {
    forEach(el.querySelectorAll(selector), result => {
      rv.add(result)
    })
  })
  return Array.from(rv)
}

/**
 * Finds each of els which matches selector.
 *
 * @function filter
 * @param {(Array.<HtmlElement>|HtmlElement)} els
 * @param {string} selector
 * @returns {Array.<HtmlElement>}
 */
function filter (els, selector) {
  return makeArray(els).filter((el) => el.matches(selector))
}

/**
 * Combines the result of find and filter.
 *
 * @function findFilter
 * @param {(Array.<HtmlElement>|HtmlElement)} els
 * @param {string} selector
 * @returns {Array.<HtmlElement>}
 */
function findFilter (els, selector) {
  const filtered = filter(els, selector)
  const found = find(els, selector)
  return filtered.concat(found)
}

export { makeArray, forEach, find, filter, findFilter }
