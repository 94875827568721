import $ from 'jquery';

function after (n, t) {
  return function () {
    return --n < 1 ? t.apply(this, arguments) : void 0;
  };
}

// function is_sublink(link, possible_sublink) {
//     // Determine whether possible_sublink is a child of
//     // link in the url tree. Returns false if the links
//     // are the same.
//
//     // add trailing slashes if they're missing, to ensure
//     // that is_sublink(/test', '/test-2') returns false,
//     // but is_sublink(/test', '/test/2') returns true.
//     if (possible_sublink.slice(-1) !== '/') {
//         possible_sublink += '/';
//     }
//     if (link.slice(-1) !== '/') {
//         link += '/';
//     }
//
//     if (link !== possible_sublink && possible_sublink.indexOf(link) === 0) {
//         return true;
//     }
//     else {
//         return false;
//     }
// };
// NS.is_sublink = is_sublink;

function getmatch (str, re, i) {
  /* Find and return the ith matched pattern in a regex, or
     return a blank string if not found */

  var match = str.match(re);
  if (match) {
    return match[i];
  } else {
    return '';
  }
}

export function getBody (html) {
  /* Get wrapped body element from an html document */

  return $('<div' + getmatch(html, /<body([^>]*>[\S\s]*)<\/body>/, 1) +
           '</div>');
}

function getTitle (html) {
  /* Get title string from an html document */

  var raw = getmatch(html, /<title>([\s\S]*?)<\/title>/, 1);
  // unescape entities
  return $('<div>').html(raw).text();
}

function getCurrentUrl () {
  /* Get fully qualified current url. */

  return window.location.pathname + window.location.search +
         window.location.hash;
}

export function isLocalLink (link) {
  /* Return true if the link points to an html page within the site. */

  var domain = getmatch(window.location.href, /[^/]+\/\/[^/]+/, 0);

  return link.is('a[href]:not([href^="http://"], [href^="https://"]), ' +
                 ' a[href^="' + domain + '"]') &&
         !link.is('[href$=".xml"], [href$=".pdf"], [href$=".jpg"], ' +
                  '[href$=".gif"], [href$=".png"], [href^="#"], ' +
                  '[href^="mailto:"]');
}

function isNumber (value) {
  return typeof value === 'number' && isFinite(value);
}

export default function AjaxLoader (ns, loader, options) {
  /* Handle ajax loading and window history state within a given namespace.
     Requires jquery and Modernizr.history

  - ns: unique namespace for this loader
  - loader: function (newBody)
    function which inserts the new body content into the page, or whatever
    subset is required

  options:
  - ajaxUrl: function (url)
    converts a normal url to an ajax one. Will use standard url if not set
  - onBeforeLoad: function (url)
    called before a load is executed, return false to cancel
  - onStateChange: function ()
    called when history state changes

  methods:
  - load: function (url, scrollTarget, state)
    load a given url, scrollTarget is an optional pixel position to scroll,
    state is used internally by popstate

  */
  this.loader = loader;
  this.ns = ns;

  this.options = $.extend({
    ajaxUrl: function (url) {
      return url + (url.match(/\?/) ? '&' : '?') + 'ajax=1';
    }
  }, options || {});

  this.bind();
}
AjaxLoader.prototype = {
  load: function (url, scrollTarget, state) {
    /* scrollTarget defaults to 0 if the url doesn't contain a # fragment. */
    var html;
    var that = this;
    var urlAnchor = url.split('#')[1];

    if (scrollTarget === undefined && !urlAnchor) {
      scrollTarget = 0;
    }

    function pushState () {
      if (window.Modernizr && window.Modernizr.history && !state) {
        // only push state if it's a new page load
        window.history.pushState({ns: that.ns}, null, url);
        if (that.options.onStateChange) {
          that.options.onStateChange();
        }
      }
    }

    if (this.options.onBeforeLoad) {
      if (this.options.onBeforeLoad(url) === false) {
        pushState();
        return;
      }
    }

    var reveal = after(2, function () {
      var title = getTitle(html);
      if (title) {
        document.title = title;
      }
      that.loader(getBody(html));
      if (!isNumber(scrollTarget) && urlAnchor) {
        window.scroll({
          top: $('#' + urlAnchor).offset().top,
          behavior: 'smooth'
        });
      }
    });

    if (isNumber(scrollTarget)) {
      window.scroll({
        top: scrollTarget,
        behavior: 'smooth'
      });
      setTimeout(reveal, 100);
    } else {
      reveal();
    }

    var ajaxUrl = this.options.ajaxUrl ? this.options.ajaxUrl(url) : url;

    function doLoad (h) {
      pushState();
      html = h;
      reveal();
    }
    $.ajax({
      type: 'get',
      url: ajaxUrl,
      success: function (h, textStatus, XMLHttpRequest) {
        doLoad(h);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        window.location = url;
        // if (xhr.status === 404) {
        //   doLoad(xhr.responseText);
        // } else {
        //   window.location = url;
        // }
      }
    });
  },
  bind: function () {
    var that = this;
    if (window.Modernizr && window.Modernizr.history) {
      $(window).bind('popstate', function (e) {
        // check state to avoid handling Crapfari's pageload popstate event
        if (e.originalEvent.state && e.originalEvent.state.ns === that.ns) {
          var url = getCurrentUrl();
          that.load(url, null, e.originalEvent.state);
          if (that.options.onStateChange) {
            that.options.onStateChange();
          }
        }
      });

      // replace original state so original loaded page works
      window.history.replaceState({ns: that.ns}, null, getCurrentUrl());
    }
  }
};
