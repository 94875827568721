import $ from "jquery"; // TODO purge
import { register, teardownContainers } from "../util/init";
import { isMobileSite, PAGE_TRANSITION_DELAY, getScrollSpeed} from "./constants";
import { updateLinkState } from "../util/link_state";
import InfScr from "./infscr";
import { wadeandletaVideoPlayerInit } from "./video";
import { VideoPlayer } from "../../videoplayer/videoplayer";
import { pushState, replaceState } from "../util/history";
import { projectDetailAjax, AzProjectsList } from "./footer";
import { getScrollController } from "./site";
import zenscroll from "zenscroll";

import Color from "color";

const cssTransform = (el, val) => {
  el.style.mozTransform = val;
  el.style.oTransform = val;
  el.style.msTransform = val;
  el.style.webkitTransform = val;
  el.style.transform = val;
};

const checkVisible = (elm, evalType, scrT) => {
  evalType = evalType || "visible";

  var vpH = $(window).height(), // Viewport Height
    y = $(elm).offset().top,
    elementHeight = $(elm).height();

  if (evalType === "visible") return y < vpH + scrT && y > scrT - elementHeight;
  if (evalType === "above") return y < vpH + scrT;
};

const tickerLoop = (el, w) => {
  var tickerWidth = w * 10;
  var counter = 0;
  return setInterval(function() {
    if (counter >= tickerWidth) {
      counter = 0;
    } else {
      counter += 4;
    }
    cssTransform(el, "translateX(-" + counter / 10 + "px)");
  }, 3);
};

const loadImages = project => {
  if (
    project.classList.contains("loading-images") ||
    project.classList.contains("loaded-images")
  ) {
    return true;
  }

  project.querySelectorAll(".image-wrap .video-player").forEach(videoPlayerEl => {
    const imageWrapEl = videoPlayerEl.parentNode.parentNode.parentNode;

    imageWrapEl.classList.add('loaded');
  });

  project.classList.add("loading-images");
  let images = project.querySelectorAll(".image-wrap .image");

  images.forEach(function(imageEl) {
    let img = imageEl.classList.contains('lazy-load') ? imageEl.querySelector("img") : null;

    if (img) {
      img.setAttribute("src", img.getAttribute("data-src"));
      if (img.getAttribute("data-width")) img.setAttribute("width", img.getAttribute("data-width"));
      if (img.getAttribute("data-height")) img.setAttribute("height", img.getAttribute("data-height"));
      img.setAttribute("alt", img.getAttribute("data-alt"));
      if (img.getAttribute("data-srcset")) {
        img.setAttribute("srcset", img.getAttribute("data-srcset"));
      }
      if (img.getAttribute("data-sizes")) {
        img.setAttribute("sizes", img.getAttribute("data-sizes"));
      }

      imageEl.classList.add("loading");

      const parentNode = imageEl.parentNode;

      img.onload = (e) => {
        parentNode.classList.add("pre-loaded");

        setTimeout(() => {
          parentNode.classList.add("loaded");
          imageEl.classList.remove("loading");
        }, 250);
      }
    }
  });
};

window.closeProjectDetail = (updateUrl, closePageEl) => {
  const html = document.documentElement;
  const headerEl = html.querySelector('#header');
  const menuEl = headerEl.querySelector(".menu");
  const bodyEl = html.querySelector('body');
  const contentEl = html.querySelector("#content");
  const pageList = closePageEl ? Array.from([closePageEl]) : contentEl.querySelectorAll('.wadeandleta-project');

  pageList.forEach(pageEl => {
    pageEl.classList.remove("scroll");
    bodyEl.classList.remove("no-scroll", "projects-project");
    pageEl.classList.add("project-transition-out");
    html.classList.add('state-project-transition-out', 'state-ajax-closing');
    if (window.azProjectsList) window.azProjectsList.reset();

    setTimeout(function() {
      teardownContainers(pageList);
      contentEl.removeChild(pageEl);
      html.classList.remove('state-project-transition-out', 'state-ajax-closing');

      if (!html.querySelector('#content article.wadeandleta-project')) {
        html.classList.remove('state-project-detail-open');
        bodyEl.classList.remove("projects-project");
        getScrollController().allowScroll('projectDetail');
      }

      html.classList.remove('state-ajax-to-ajax');

      if (updateUrl) {
        pushState(bodyEl.getAttribute("old-url"));
        document.title = bodyEl.getAttribute("old-title");
        updateLinkState([headerEl]);
      }
    }, PAGE_TRANSITION_DELAY);
  });
}

const projectInit = (projectEl, single) => {
  if (projectEl.classList.contains('project-initialised')) return;

  const html = document.documentElement;
  const indexInner = projectEl.parentNode.parentNode;
  const projectInnerEl = projectEl.querySelector('.project-inner');
  const image = projectEl.querySelector(".image-wrap .image");
  const wrap = projectEl.querySelector(".project-wrap");
  const inner = wrap.querySelector(".project-inner");
  const tickerTape = projectEl.querySelector(".ticker-tape");
  const tickerTapeIn = projectEl.querySelector(".ticker-tape-inner");
  const linkEl = projectEl.querySelector('a');
  const isLarge = projectEl.classList.contains('landscape');
  let timer;
  let cursorTimer;

  const projectAnimate = () => {
    if (!isMobileSite()) {
      if (
        (single || html.classList.contains("state-projects-open")) &&
        !projectEl.classList.contains("detail-visible")
      ) {
        projectEl.classList.add("detail-visible");

        var item = tickerTape.querySelector(".item");
        var k = Math.ceil(tickerTape.offsetWidth / item.offsetWidth);

        for (var i = 0; i < k; i++) {
          tickerTapeIn.appendChild(item.cloneNode(true));
        }

        if (tickerTapeIn.firstChild) {
          timer = tickerLoop(tickerTapeIn, tickerTapeIn.firstChild.offsetWidth);
        }
      }
    }
  };

  if (image) {
    tickerTape.style.width = image.style.paddingTop;
  }
  if (single) {
    projectAnimate();
  }
  projectInnerEl.addEventListener("mouseenter", function() {
    clearTimeout(cursorTimer);
    projectAnimate();
    html.classList.add('state-cursor-show');

    cursorTimer = setTimeout(() => {
      html.classList.add('state-cursor-show-' + (isLarge ? 'large' : 'medium'));
    }, 250);
  });
  if (!single) {
    projectInnerEl.addEventListener("mouseleave", function() {
      html.classList.remove('state-cursor-show', 'state-cursor-show-large', 'state-cursor-show-medium');
      projectEl.classList.remove("detail-visible");
      clearTimeout(timer);
      clearTimeout(cursorTimer);
      while (tickerTapeIn.firstChild) {
        tickerTapeIn.removeChild(tickerTapeIn.firstChild);
      }
      cssTransform(tickerTapeIn, "");
    });
  }
  if (linkEl) {
    linkEl.addEventListener('click', e=> {
      e.stopPropagation();
      e.preventDefault();

      if (html.classList.contains('state-ajax-page-loading')) return false;

      document.documentElement.querySelector('body').setAttribute("old-url", window.location.pathname);
      document.documentElement.querySelector('body').setAttribute("old-title", document.title);
      projectDetailAjax.load(linkEl.getAttribute('href'), "none");

      return false;
    })
  }
  projectEl.classList.add('project-initialised');
};

function projectsIndex(container) {
  const html = document.documentElement;
  const bodyEl = document.querySelector("body");
  const contentEl = bodyEl.querySelector(":scope > #content");
  const $container = $(container);
  const indexInner = container.querySelector(".index-inner");
  const projectListWrap = indexInner.querySelector(".project-list");
  const artworkListWrap = indexInner.querySelector(".artwork-list");
  const infscrList = [];
  const projectVideoList = [];
  const artWorkVideoList = [];
  let projectList = projectListWrap.querySelectorAll(".project-listing");
  let artworkList = artworkListWrap.querySelectorAll(".artwork-listing");
  const cursorEl = html.querySelector('#cursor');
  let windowSize = [window.innerWidth, window.innerHeight];
  let isMobile = isMobileSite();
  let firstProjectTop = projectList ? projectList[0].offsetTop : 0;

  const scrollState = (el) => {
    if (el.querySelector(".next")) {
      document.body.classList.add("infinite-scrolling");
    } else {
      document.body.classList.remove("infinite-scrolling");
    }
  };
  scrollState(projectListWrap);
  scrollState(artworkListWrap);

  const artworkInit = artworkEl => {
    const innerEl = artworkEl.querySelector(".artwork-inner");
    const imageEl = artworkEl.querySelector(".video-wrap .image");

    if (imageEl) {
      innerEl.style.paddingTop = imageEl.style.paddingTop;
    }
  };

  // Projects inifite scroll
  infscrList.push(InfScr($(projectListWrap), {
    contentSelector: ".project-list .project-listing",
    nextSelector: ".next",
    autoOffset: 2500,
    beforeLoad: function() {
      html.classList.add("state-projects-loading-more");
    },
    onLoad: function(newContent, container) {
      projectList = projectListWrap.querySelectorAll(".project-listing");
      projectList.forEach((el) => {
        projectInit(el, false)
      });
      html.classList.remove("state-projects-loading-more");
      scrollState(projectListWrap);
    }
  }));

  // Artworks inifite scroll
  infscrList.push(InfScr($(artworkListWrap), {
    contentSelector: ".artwork-list .artwork-listing",
    nextSelector: ".next",
    autoOffset: 2500,
    beforeLoad: function() {
      artworkListWrap.classList.add("loading-more");
    },
    onLoad: function(newContent, container) {
      artworkList = artworkListWrap.querySelectorAll(".artwork-listing");
      artworkList.forEach(artworkInit);
      artworkListWrap.classList.remove("loading-more");
      scrollState(artworkListWrap);
    }
  }));


  artworkList.forEach(artworkInit);
  projectList.forEach(el => {
    projectInit(el, false);
  });

  cursor.style.display = 'none';

  // Desktop only
  if (!isMobile) {
    bodyEl.addEventListener('mousemove', e=> {
      cursorEl.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0px)`;
      cursor.style.display = '';
    });
  }

  if (html.classList.contains("state-projects-open") && projectList.length > 0) {
    let scrTop =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0);

    if (projectList[0].offsetTop - 70 > scrTop) {
      const scrollToY = projectList[0].offsetTop - html.querySelector('#header-contact').offsetTop;
      zenscroll.toY(scrollToY, getScrollSpeed(window.scrollY, scrollToY));
    }
  }

  let projectsPageScrollTimer;

  const projectsVideoInitOrLoad = (el, i) => {
    const videoEl = el.querySelector('.video-player');

    if (!videoEl) return;

    if (i in projectVideoList) {
      projectVideoList[i].loadVideo();
    } else {
      projectVideoList.push(videoEl ? new VideoPlayer(videoEl) : {});
    }
  }

  const scrollProjects = scrT => {
    clearTimeout(projectsPageScrollTimer);

    if (html.classList.contains("state-projects-open")) {
      let project;
      projectList.forEach((el, i) => {
        if (checkVisible(el, "visible", scrT)) {
          project = el;

          for (
            var j = Math.max(i - 1, 0);
            j < Math.min(i + 4, projectList.length);
            ++j
          ) {
            loadImages(projectList[j]);
          }

          // Play the video if there is one
          projectsVideoInitOrLoad(el, i);

          if (projectVideoList[i]) projectVideoList[i].play();
        }
      });

      if (scrT <= windowSize[0] * .70 && !isMobile) {
        projectsPageScrollTimer = setTimeout(() => {
          if (scrT <= windowSize[0] * .70 && !html.classList.contains('page-scrolling')) {
            const scrollToY = firstProjectTop - html.querySelector('#header-contact').offsetTop;
            zenscroll.toY(scrollToY, getScrollSpeed(window.scrollY, scrollToY));
          }
        }, 100);
      }
    }
  };

  const artworkVideoInitOrLoad = (el, i) => {
    const videoEl = el.querySelector('.video-player');

    if (i in artWorkVideoList) {
      artWorkVideoList[i].loadVideo();
    } else {
      artWorkVideoList.push(videoEl ? new VideoPlayer(videoEl) : {});
    }
  }

  const scrollArtworks = scrT => {
    if (!html.classList.contains("state-projects-open")) {
      artworkList.forEach((el, i) => {
        if (checkVisible(el, "visible", scrT)) {
          for (var j = 0; j < i - 2 && i > 1; j++) {

            if (artWorkVideoList[j]) artWorkVideoList[j].unloadVideo();
          }
          artworkVideoInitOrLoad(artworkList[i], i);
          if (i + 1 < artworkList.length) {
            artworkVideoInitOrLoad(artworkList[i+1], i+1);
          }
          if (i - 1 >= 0) {
            artworkVideoInitOrLoad(artworkList[i-1], i-1);
          }
          if (artWorkVideoList[i]) artWorkVideoList[i].play();
        }
      });
    }
  }

  if (artworkList.length > 0) artworkVideoInitOrLoad(artworkList[0], 0);
  if (artworkList.length > 1) artworkVideoInitOrLoad(artworkList[1], 1);

  window.openProjects = () => {
    scrollProjects(window.scrollY);
  };

  const BreakException = {};
  let pageResize;
  let lastScrollY = window.scrollY;

  return {
    resize: (...args) => {
      clearTimeout(pageResize);
      windowSize = args;
      isMobile = isMobileSite();
      firstProjectTop = projectList ? projectList[0].offsetTop : 0;

      infscrList.forEach((infscr) => {
        infscr.resize.apply(infscr, args);
      });

      if (!isMobile) {
        pageResize = setTimeout(() => {
          if (html.classList.contains('state-projects-open') && !html.classList.contains('state-ajax-page-loading')) {
            const footerEl = html.querySelector('footer#footer');

            if (checkVisible(footerEl, "visible", window.scrollY)) {
              footerEl.scrollIntoView();
            } else {
              let selectedProjectEl = projectList[0];

              try {
                projectList.forEach(projectEl => {
                  if (checkVisible(projectEl, "visible", window.scrollY)) {
                    selectedProjectEl = projectEl;
                    throw BreakException;
                  };
                });
              } catch (e) {
                if (e !== BreakException) throw e;
              }

              const scrollToY = selectedProjectEl.offsetTop - html.querySelector('#header-contact').offsetTop;
              window.scrollTo(0, scrollToY);
            }
          }
        }, 250);
      }
    },
    scroll: (...args) => {
      if (zenscroll.moving() && lastScrollY > args[0] && firstProjectTop > args[0]) zenscroll.stop();
      infscrList.forEach((infscr) => {
        infscr.scroll.apply(infscr, args);
      });
      scrollProjects(args[0]);
      scrollArtworks(args[0]);
      lastScrollY = args[0]
    }
  };
}
register(".projects-index", projectsIndex);

function projectsProject(container) {
  const html = document.documentElement;
  const headerContactEl = html.querySelector('#header-contact');
  const headerContactTextEl = headerContactEl.querySelector('.text-wrap');
  const contentWrapEl = container.querySelector('.project-content-wrap');
  const menuButtonEl =  html.querySelector('.menu .studio .gif-wrap');
  const projectsAzWrapEl = container.querySelector('.projects-az-wrap');

  const color = contentWrapEl.getAttribute('data-color');
  const videoList = [];
  let windowSize = [window.innerWidth, window.innerHeight];
  let isMobile = isMobileSite();
  let containerH = container.offsetHeight;
  let pageScrollTimer;

  const azProjectsList = new AzProjectsList(projectsAzWrapEl, container);

  html.classList.add('state-project-detail-open', 'state-contact-fixed');

  container.querySelectorAll('.image-list .image-listing.is-video').forEach(el => {
    const videoEl = el.querySelector(".video-player");
    if (videoEl) videoList.push(wadeandletaVideoPlayerInit(videoEl, {}));
  });

  container.addEventListener('scroll', e => {
    clearTimeout(pageScrollTimer);
    const scrT = container.scrollTop;
    const azProjectsListTop = projectsAzWrapEl.offsetTop;
    html.classList.add('page-scrolling');

    if (azProjectsList.canvasEl) azProjectsList.canvasEl.style.transform = `translateY(${Math.max(125, scrT - azProjectsListTop)}px)`;

    headerContactEl.style.transform =  `translateY(-${Math.max(0, (scrT + containerH) - azProjectsListTop + Math.max(0, headerContactTextEl.offsetWidth - containerH))}px) rotate(90deg)`;

    pageScrollTimer = setTimeout(() => {
      html.classList.remove('page-scrolling');
    }, 100);
  });

  loadImages(container);

  const resizePage = (...args) => {
    containerH = container.offsetHeight - menuButtonEl.offsetHeight;
    azProjectsList.resize(...args);

    if (isMobile) {
      container.style.height = `${windowSize[1]}px`;
    } else {
      container.style.height = '';
    }
  }

  resizePage(...windowSize);

  return {
    resize: (...args) => {
      windowSize = args;
      isMobile = isMobileSite();
      resizePage(...windowSize);
    },
    scroll: (...args) => {},
    teardown: () => {
      Array.from(videoList).forEach((obj, i) => {
        obj.teardown()
        videoList[i] = null;
      })
      azProjectsList.destroy();
    }
  };
}
register(".wadeandleta-project", projectsProject);
