import { forEach } from 'j/util/traversing'
import events from 'add-event-listener'

/**
 * Adds an event listener to each of els, calling handler upon the specified
 * event for each of els.
 *
 * @function addEventListener
 * @param {(Array.<HtmlElement>|HtmlElement)} els
 * @param {string} eventNames
 * @param {function} handler
 */
function addEventListener (els, eventNames, handler, opts = {}) {
  if (els.nodeType || els === window) {
    els = [els]
  }

  eventNames.split(' ').forEach(eventName =>
    forEach(els, el => events.addEventListener(el, eventName, handler, opts)))
}

/**
 * Removes an event listener from each of els.
 *
 * @function removeEventListener
 * @param {(Array.<HtmlElement>|HtmlElement)} els
 * @param {string} eventNames
 * @param {function} [handler]
 */
function removeEventListener (els, eventNames, handler, opts = {}) {
  if (els.nodeType || els === window) {
    els = [els]
  }

  eventNames.split(' ').forEach(eventName =>
    forEach(els,
      el => events.removeEventListener(el, eventName, handler, opts)))
}

export { addEventListener, removeEventListener }
