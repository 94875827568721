import {addHandler} from '../util/scroll_resize';

// see _constants.scss
export const PAGE_TRANSITION_DELAY = 750;
export const PAGE_SCROLL_SPEED = 1000;
export const MAX_DESKTOP = 1560;
export const MAX_PHONE = 959;
export const MAX_MOBILE = MAX_PHONE;

let WIN_WIDTH = window.innerWidth;
let WIN_HEIGHT = window.innerHeight;

addHandler({
  resize: function(...args) {
    WIN_WIDTH = args[0];
    WIN_HEIGHT = args[1];
  }
});

export function getScrollSpeed(fromY, toY) {
  const diffY = Math.abs(fromY - toY);
  return diffY > 3000 ? Math.max((diffY / 10000) * PAGE_SCROLL_SPEED, 350) : Math.max((diffY / 1000) * PAGE_SCROLL_SPEED, 350);
}

export function isMobileSite() {
  return WIN_WIDTH <= MAX_MOBILE;
}
