import {register} from '../util/init';
import {isMobileSite} from './constants';

function circlePoint(t, r, x, y) {
  return [x + r * Math.cos(t), y - r * Math.sin(t)]
}

class PathText {
  constructor(scope, rawText, path, layer, style) {
    this.scope = scope;
    this.rawText = rawText;
    this.glyphTexts = [];
    this.xOffsets = [0];
    this.rOffset = 180;
    this.glyphAngles = [];

    this.path = path;
    this.layer = layer;
    this.style = style || {};

    this.init();
  }

  init() {
    this.initText();
    this.getOffsets();
    this.rotateGlyphs(true);
  }

  initText() {
    const that = this;

    for (var i = this.rawText.length - 1; i >= 0; i--) {
      that.glyphTexts[i] = that.createPointText(that.rawText.substring(i, i+1));
      that.glyphTexts[i].justification = "center";
      that.layer.addChild(that.glyphTexts[i]);
    }
  }

  createPointText(s) {
    var text = new this.scope.paper.PointText();
    text.content = s;

    if (this.style.font) text.font = this.style.font;
    if (this.style.fontColor) text.fillColor = this.style.fontColor;
    if (this.style.fontFamily) text.fontFamily = this.style.fontFamily;
    if (this.style.fontSize) text.fontSize = this.style.fontSize;
    if (this.style.fontWeight) text.fontWeight = this.style.fontWeight;

    return text;
  }

  getOffsets() {
    const that = this;
    this.xOffsets = [0];

    for (var i = 1; i < this.rawText.length; i++) {
      var pairText = that.createPointText(that.rawText.substring(i - 1, i + 1));
      pairText.remove();
      that.xOffsets[i] = that.xOffsets[i - 1] + pairText.bounds.width -
          that.glyphTexts[i - 1].bounds.width / 2 - that.glyphTexts[i].bounds.width / 2;
    }
  }

  rotateGlyphs(canRotate) {
    const that = this;
    // set point for each glyph and rotate glyph aorund the point
    for (var i = 0; i < this.rawText.length; i++) {
      var centerOffs = that.xOffsets[i];
      if (that.path.length < centerOffs) {
        if (that.path.closed) {
            centerOffs = centerOffs % that.path.length;
        }  else {
            centerOffs = undefined;
        }
      }
      if (centerOffs === undefined) {
        that.glyphTexts[i].remove();
      } else {
        var pathPoint = that.path.getPointAt(centerOffs);
        that.glyphTexts[i].point = pathPoint;
        if (canRotate) {
          var tan = that.path.getTangentAt(centerOffs);
          var oldAngle = i in that.glyphAngles ? that.glyphAngles[i] : 0;
          var newAngle = tan.angle - this.rOffset;
          that.glyphAngles[i] = newAngle;
          that.glyphTexts[i].rotate(newAngle - oldAngle, pathPoint);
        }
      }
    }
  }

  update(event) {
    this.rotateGlyphs(event.count % 10 == 0);
  }
}

export class Dot {
  constructor(scope, center, num_points, width, delay, text, textLayer, textStyle) {
    this.scope = scope;
    this.path = new scope.paper.Path();
    this.width = width;
    this.radius = width / 2;
    this.center = center;
    this.num_points = num_points;
    this.endPoints = []
    this.finalPoints = [];
    this.finalLengths = [];
    this.frames = 0;
    this.scale = 1;
    this.delay = delay;
    this.start = false;
    this.end = false;
    this.locked = true;
    this.randomSeed = Math.random();
    this.textRaw = text;
    this.textLayer = textLayer;
    this.textStyle = textStyle || {};
    this.pathText = {};

    this.init();
  }

  theta(i) {
    return 2 * Math.PI / this.num_points * i;
  }

  init() {
    this.initCircle();
    this.initFinal();

    if (this.textRaw) {
      this.pathText = new PathText(this.scope, this.textRaw, this.path, this.textLayer, this.textStyle);
    }
  }

  initCircle() {
    for (var i = 0; i < this.num_points; i++) {
      var theta = this.theta(i);
      var point = new this.scope.paper.Point(circlePoint(theta, this.radius, this.center.x, this.center.y));
      this.endPoints[i] = new this.scope.paper.Point(circlePoint(theta, this.radius * 1.3184713376, this.center.x, this.center.y));
      this.path.add(point);
    }
    this.path.closed = true;
    this.path.smooth({ type: 'continuous' });
  }

  initFinal() {
    var that = this;

    for (var i = 0; i < this.num_points; i++) {
      let p = that.path.segments[i].point;
      that.finalPoints.push(p);
      that.finalLengths.push(p.subtract(that.center).length);
    };
    for (var i = 0; i < 6; i++) {
      that.finalPoints = that.getNextGrowPoints(0, false, that.finalPoints);
    }
  }

  drawPoint(i, point) {
    this.path.segments[i].point.set(point.x, point.y);
    this.path.smooth({ type: 'continuous' });
  }

  growToPoints() {
    for (var i = 0; i < this.num_points; i++) {
      var v0 = this.path.segments[i].point.subtract(this.center);
      var v1 = this.finalPoints[i].subtract(this.center);

      v0.length = this.finalLengths[i] + (v1.length - this.finalLengths[i]) / this.delay * this.frames * this.scale;

      this.drawPoint(i, v0.add(this.center));
    }
  }

  getNextGrowPoints(seed, update, points) {
    var that = this;
    var newPoints = [];
    var oldPoints = [];

    if (points) {
      oldPoints = points;
    } else {
      for (var i = 0; i < this.num_points; i++) {
        oldPoints.push(this.path.segments[i].point);
      }
    }

    for (var i = 0; i < this.num_points; i++) {
      var p = oldPoints[i];
      var sinSeed = seed + (i + i % 10) * 100;
      var d = p.getDistance(this.center);
      var newP;

      var newD = (Math.sin(sinSeed / 100) + 1) * this.width / 2;
      var t = (d + newD) / d * this.scale;

      var xPos = ((1 - t) * this.center.x + t * this.endPoints[i].x);
      var yPos = ((1 - t) * this.center.y + t * this.endPoints[i].y);

      newP = new this.scope.paper.Point(xPos, yPos);

      newPoints.push(newP);
      if (update) {
        this.drawPoint(i, newP);
      }
    }
    return newPoints;
  }

  growToSeed() {
    var ready = true;
    var newPoints;

    if (this.frames < this.delay) {
      this.growToPoints();

      ready = false;
      this.start = true;
    }

    return ready;
  }

  resetPoints(lock) {
    var that = this;
    this.locked = true;
    this.start = false;

    setTimeout(function () {
      that.path.removeSegments();
      that.initCircle();
      that.frames = 0;
      that.locked = lock;
      that.end = false;
    }, 50);
  }

  frame(event) {
    var that = this;

    if (!that.locked) {
      if (that.end || that.growToSeed()) {
        that.getNextGrowPoints((that.frames - this.delay), true);
        if (that.pathText.update) that.pathText.update(event);

        if (!that.end) {
          that.frames = this.delay;
        }
        that.end = true;
      }
      that.frames += 1;
    }
  }
}

export class Grid {
  constructor(scope, canvas, points, positions, allowed, width, delay, ratio, dotStyle) {
    this.scope = scope;
    this.canvas = canvas;
    this.dots = [];
    this.points = points;
    this.positions = positions;
    this.allowed = allowed;
    this.width = width;
    this.current;
    this.raster;
    this.rasterLayer;
    this.imageCache = {};
    this.layers = [];
    this.delay = delay;
    this.ratio = ratio;
    this.dotStyle = dotStyle || {};
    this.canPlay = false;

    this.init();
  }

  init() {
    this.initDots();
  }

  initDots() {
    var that = this;
    for (var i=0; i<this.positions.length; i++) {
      var d = new Dot(this.scope, this.getCenter(this.positions[i]), this.points, this.width, this.delay);
      this.dots.push(d);
      this.layers.push(new this.scope.paper.Layer({
        children: [d.path],
        clipped: false,
        fillColor: this.getColor(),
      }));
    }
  }

  getCenter (p) {
    return new this.scope.paper.Point(p[0] * this.canvas.width * this.ratio, p[1] * this.canvas.height * this.ratio);
  }

  loadImage(id) {
    if (id in this.imageCache) {
      this.rasterLayer = this.imageCache[id];
      this.raster = this.rasterLayer.firstChild;
    } else {
      const bounds = this.scope.view.bounds.clone();
      bounds.width = bounds.width;
      this.raster = new this.scope.paper.Raster(id);

      if (this.raster.width > this.raster.height) {
        this.raster.height = this.raster.height / this.raster.width * bounds.width / this.ratio;
        this.raster.width = bounds.width / this.ratio;
      } else {
        this.raster.height = this.raster.height / this.raster.width * bounds.width;
        this.raster.width = bounds.width;
      }

      const lay = new this.scope.paper.Layer({
        children: [this.raster],
        clipped: false,
      });
      lay.scale(this.ratio);

      this.rasterLayer = lay;
      this.imageCache[id] = lay;
    }

    return this.rasterLayer;
  }

  swapImage(id) {
    this.getLayer().addChild(this.loadImage(id));
    this.getLayer().removeChildren(1, 2);
    this.centerImage(this.dots[this.current].center);
    this.dots[this.current].path.smooth({ type: 'continuous' });
  }

  centerImage(center) {
    this.raster.position = center;
  }

  randomDot() {
    return this.allowed[Math.floor(Math.random() * this.allowed.length)];
  }

  getCurrent() {
    return this.dots[this.current];
  }

  getLayer() {
    return this.layers[this.current];
  }

  getColor() {
    return this.dotStyle.color || new this.scope.paper.Color(0, 0, 0);
  }

  activate(swap) {
    var oldL = false;
    if (!swap && this.current >= 0) {
      this.resetCurrent(true);
      oldL = this.getLayer();
    }
    if (!swap || !this.current) {
      this.current = this.randomDot();
    }
    this.centerImage(this.dots[this.current].center);
    this.getLayer().addChild(this.rasterLayer);
    this.getLayer().clipped = true;
    this.getLayer().bringToFront();
  }

  resetCurrent(lock) {
    if (this.current >= 0) {
      this.getCurrent().resetPoints(lock);
    }
  }
  removeImage() {
    if (this.current >= 0) {
      this.getLayer().removeChildren(1);
      this.getLayer().clipped = false;
      this.getLayer().fillColor = this.getColor();
    }
  }
  play () {
    const that = this;

    if (!this.canPlay) {
      this.canPlay = true;

      that.layers.forEach((layer, i) => {
        layer.visible = false;
        layer.visible = true;
      });

      this.scope.view.onFrame = (e) => {
        that.dots.forEach(dot => {
          dot.frame(e);
        });
      }
    }
  }
  destroy() {
    this.canPlay = false;
    this.scope.remove();
    this.canvas.remove();
    this.scope = null;
    this.canvas = null;
  }
}
