import $ from "jquery"; // TODO purge
import { updateLinkState } from "../util/link_state";
import { initContainers } from "../util/init";
import {get} from "../util/fetch";
import {isMobileSite, PAGE_TRANSITION_DELAY} from './constants';
import { Grid, Dot } from "./paper";
import AjaxLoader from "../util/ajax_util";
import { getScrollController } from './site'

const checkProjectDetailOpen = () => {
  const html = document.documentElement;

  if (html.classList.contains('state-project-detail-open') || html.querySelector('#content article.wadeandleta-project')) return true;

  return false;
}

const checkStudioOpen = () => {
  const html = document.documentElement;

  if (html.classList.contains('state-studio-open') || html.querySelector('#content article.wadeandleta-studio')) return true;

  return false;
}

const gridConvert = (cols, gridCols) => {
  return cols / gridCols;
}

const GRID_TOTAL_COLS = 13.25;

const GRID_POINTS = 8;
const GRID_DOT_POSITIONS = [
  [gridConvert(3, GRID_TOTAL_COLS), gridConvert(2.25, GRID_TOTAL_COLS)],
  [gridConvert(9, GRID_TOTAL_COLS), gridConvert(2.25, GRID_TOTAL_COLS)],
  [gridConvert(6, GRID_TOTAL_COLS), gridConvert(5, GRID_TOTAL_COLS)],
  [gridConvert(3, GRID_TOTAL_COLS), gridConvert(7.75, GRID_TOTAL_COLS)],
  [gridConvert(9, GRID_TOTAL_COLS), gridConvert(7.75, GRID_TOTAL_COLS)],
  [gridConvert(6, GRID_TOTAL_COLS), gridConvert(10.5, GRID_TOTAL_COLS)],
];
const GRID_DOT_DISPLAY = [0, 2, 3];
const GRID_DELAY = 50;

export const projectDetailAjax = new AjaxLoader(
  "wadeandleta",
  function(newBody) {
    const html = document.documentElement;
    const bodyEl = html.querySelector("body");
    const headerEl = html.querySelector('#header');
    const contentEl = bodyEl.querySelector("#content");
    const newContent = $(newBody).find('.wadeandleta-project');

    newContent.addClass('ajax-load');
    $(bodyEl).addClass($(newBody).attr("class"));
    bodyEl.classList.remove("no-scroll");
    $(contentEl).append(newContent);
    const pageList = contentEl.querySelectorAll('.wadeandleta-project');
    const pageEl = pageList[pageList.length - 1];
    pageEl.classList.remove("scroll");
    pageEl.classList.add("project-transition-in");
    getScrollController().preventScroll('projectDetail', [pageEl]);

    updateLinkState([headerEl]);
    initContainers([pageEl]);

    setTimeout(function() {
      pageEl.classList.remove("project-transition-in");

      setTimeout(function() {
        bodyEl.classList.add("no-scroll");
        pageEl.classList.add("scroll");
        html.classList.remove('state-ajax-page-loading', 'state-project-transition-in');

        if (window.azProjectsList) window.azProjectsList.destroy();
      }, PAGE_TRANSITION_DELAY);
    }, 250);
  },
  {
    onBeforeLoad: function(url) {
      document.documentElement.classList.add('state-ajax-page-loading', 'state-project-transition-in');

      if (checkProjectDetailOpen() || checkStudioOpen()) {
        document.documentElement.classList.add('state-ajax-to-ajax');
      }
    }
  }
);

export class AzProjectsList {
  constructor (el, projectDetailEl) {
    this.container = el;
    this.canvasWrapEl = el.querySelector('.canvas-wrap');
    this.canvasEl = this.canvasWrapEl.querySelector('canvas');
    this.projectListWrapEl = el.querySelector('.project-list-wrap');
    this.projectDetailEl = projectDetailEl;

    this.projectList = [];

    this.windowSize = [window.innerWidth, window.innerHeight];
    this.canvasSize = [this.canvasWrapEl.offsetWidth, this.canvasWrapEl.offsetHeight];

    this.timer;
    this.leaveTimer;
    this.resizeTimer;

    this.hasReset = true;
    this.locked = false;
    this.grid;
    this.paperScope;

    this.init()
  }

  init () {
    this.listInit();
  }

  loadProjects (el, call) {
    return new Promise(function(resolve, reject) {
      get(window.URLS['projects_az']).then(response => {
        return response.text();
      })
      .then(text => {
        el.innerHTML = text;
        resolve();
      });
    })
  }

  listInit () {
    const html = document.documentElement;
    var that = this;
    let leaveTimer;

    const projectInit = (el, i) => {
      if (that.grid) {
        const linkEl = el.querySelector("span");

        linkEl.addEventListener("mouseenter", () => {
          clearTimeout(that.leaveTimer);

          if (that.hasReset) {
            that.hasReset = false;
            that.grid.loadImage("proj-" + linkEl.getAttribute("data-id"));
            that.grid.activate();

            that.timer = setTimeout(() => {
              that.grid.resetCurrent(false);
            }, 150);
          } else {
            that.grid.swapImage("proj-" + linkEl.getAttribute("data-id"));

            if (!that.grid.getCurrent().start) {
              that.grid.resetCurrent(false);
            }
          }
        });
        linkEl.addEventListener("mouseleave", () => {
          clearTimeout(that.timer);
          that.leaveTimer = setTimeout(() => {
            that.hasReset = true;
            that.grid.resetCurrent(true);
            that.grid.removeImage();
          }, 50);
        });
        linkEl.addEventListener("click", e => {
          e.stopPropagation();
          e.preventDefault();

          if (html.classList.contains('state-ajax-page-loading')) return false;

          if (this.projectDetailEl) {
            that.locked = true;

            setTimeout(() => {
              window.closeProjectDetail(false, this.projectDetailEl);
            }, PAGE_TRANSITION_DELAY * 2)
          }

          document.documentElement.querySelector('body').setAttribute("old-url", window.location.pathname);
          document.documentElement.querySelector('body').setAttribute("old-title", document.title);
          projectDetailAjax.load(linkEl.getAttribute('href'), "none");

          return false;
        });
      }
    }

    that.loadProjects(that.projectListWrapEl).then(() => {
      that.canvasInit();
      that.projectList = that.projectListWrapEl.querySelectorAll('.project-list .project-listing');
      that.projectList.forEach(projectInit);
    });
  }

  canvasInit () {
    const menuButtonEl =  document.documentElement.querySelector('.menu .studio .gif-wrap');
    const pixelRatio = 1 / window.devicePixelRatio;
    const containerW = this.canvasWrapEl.offsetWidth;
    const color = this.container.getAttribute('data-color');
    const style = {};

    this.canvasEl = document.createElement('canvas');
    this.canvasEl.setAttribute('resize', '');
    this.canvasWrapEl.children[0].appendChild(this.canvasEl);

    this.paperScope = new paper.PaperScope();
    this.paperScope.setup(this.canvasEl);

    if (color) style.color = color;

    const parentEl = this.canvasEl.parentNode.parentNode;

    if (this.windowSize[1] - menuButtonEl.offsetHeight < containerW) {
      const width = ((this.windowSize[1] - menuButtonEl.offsetHeight));
      parentEl.classList.add('resized');
      parentEl.style.width = `${width}px`;
      parentEl.style.paddingTop = `${width}px`;
      parentEl.parentNode.style.minHeight = `${width}px`;
    } else {
      parentEl.classList.remove('resized');
      parentEl.style.width = '';
      parentEl.style.paddingTop = '';
      parentEl.parentNode.style.minHeight = '';
    }

    this.grid = new Grid(
      this.paperScope,
      this.canvasEl,
      GRID_POINTS,
      GRID_DOT_POSITIONS,
      GRID_DOT_DISPLAY,
      this.canvasEl.width / GRID_TOTAL_COLS * 2.25 * pixelRatio,
      GRID_DELAY,
      pixelRatio,
      style
    );

    this.paperScope.view.viewSize = new this.paperScope.Size(containerW, containerW);
    this.paperScope.view.draw();

    this.grid.play();
  }

  resize (...args) {
    if (this.locked) return;

    const that = this;
    clearTimeout(this.resizeTimer);
    this.windowSize = args;
    this.canvasSize = [this.canvasWrapEl.offsetWidth, this.canvasWrapEl.offsetHeight];

    that.canvasWrapEl.classList.add('resizing');

    this.resizeTimer = setTimeout(() => {
      that.canvasWrapEl.classList.remove('resizing');
    }, 250);

    if (this.paperScope && this.paperScope.view) {
      this.paperScope.view.viewSize = new this.paperScope.Size(this.canvasSize[0], this.canvasSize[0]);
      that.reset();
    }
  }

  reset () {
    if (this.locked) return;

    this.destroy();
    this.canvasInit();
  }

  destroy () {
    if (this.grid) {
      this.grid.destroy();
      this.grid = null;
    }

    this.canvasWrapEl.style.width = '';
    this.canvasWrapEl.style.paddingTop = '';
    this.canvasWrapEl.parentNode.style.minHeight = '';
  }
}

export const footerInit = (footerEl) => {
  const html = document.documentElement;
  const bodyEl = html.querySelector('body');
  const headerContactEl = html.querySelector('#header-contact');
  const footerInnerEl = footerEl.querySelector('.footer-inner');
  const projectsAzWrapEl = footer.querySelector('.projects-az-wrap');
  const canvasWrapEl = footerEl.querySelector('.canvas-wrap');
  const projectListWrapEl = footerEl.querySelector('.project-list-wrap');
  let canvasSize = [canvasWrapEl.offsetWidth, canvasWrapEl.offsetHeight];
  let contactH = headerContactEl.querySelector('.text-wrap').offsetWidth;
  let windowSize = [window.innerWidth, window.innerHeight];
  let isMobile = isMobileSite();
  let lastScroll;

  let reset = true;
  let timer;



  let azProjectsList;

  if (!bodyEl.classList.contains('no-footer')) {
    // Load the projects in via Ajax
    azProjectsList = new AzProjectsList(projectsAzWrapEl);
  }

  const footerScroll = (...args) => {
    if (isMobile) {
      html.classList[!html.classList.contains('state-projects-loading-more') && !html.classList.contains('state-home-open') && !html.classList.contains('state-project-detail-open') && footerEl.offsetTop < args[0] + 225 ? 'add' : 'remove']('state-footer-visible');
    } else {
      html.classList[footerEl.offsetTop < args[0] + windowSize[1] - 185 ? 'add' : 'remove']('state-footer-visible');
    }

    const contactFullH = contactH + headerContactEl.offsetTop;
    let transformY = 0;

    if (!html.classList.contains('state-projects-loading-more')) {
      if (!checkStudioOpen() && !checkProjectDetailOpen()) {
        const menuButtonEl =  html.querySelector('.menu .studio .gif-wrap');
        transformY = Math.max(0, (args[0] + windowSize[1]) - footerEl.offsetTop - (windowSize[1] - contactFullH));
        if (((args[0] + menuButtonEl.offsetHeight) - footerInnerEl.offsetTop) >= 0) {
          canvasWrapEl.classList.add('fixed');
          canvasWrapEl.style.transform = `translate3d(-50%, ${menuButtonEl.offsetHeight}px, 0)`;
        } else {
          canvasWrapEl.classList.remove('fixed');
          canvasWrapEl.style.transform = '';
        }
      } else {
        canvasWrapEl.classList.remove('fixed');
        canvasWrapEl.style.transform = '';
      }

      headerContactEl.style.transform = `translateY(-${transformY}px) rotate(90deg)`;
    } else {
      canvasWrapEl.classList.remove('fixed');
      canvasWrapEl.style.transform = '';
    }
    lastScroll = args[0];
  }

  window.azProjectsList = azProjectsList;
  window.footerScroll = footerScroll;

  setTimeout(() => {
    window.footerScroll(window.scrollY);
  }, 50);

  return {
    resize: (...args) => {
      windowSize = args;
      isMobile = isMobileSite();
      contactH = headerContactEl.querySelector('.text-wrap').offsetWidth;

      if (azProjectsList) {
        if (!html.classList.contains('state-studio-transition-in') && !html.classList.contains('state-studio-transition-out') && !html.classList.contains('state-footer-reset')) {
          azProjectsList.resize(...windowSize);
        }
      }
    },
    scroll: (...args) => {
      footerScroll(...args);
    }
  }
}
