import AjaxLoader from "../util/ajax_util";
import { pushState } from "../util/history";
import { initContainers, teardownContainers } from "../util/init";
import { updateLinkState } from "../util/link_state";
import {isMobileSite, PAGE_TRANSITION_DELAY, getScrollSpeed} from './constants';
import {VideoPlayer} from '../../videoplayer/videoplayer';
import { getScrollController } from './site';
import $ from "jquery"; // TODO purge
import SuperGif from 'jsgif';
import zenscroll from "zenscroll";

const checkProjectDetailOpen = () => {
  const html = document.documentElement;

  if (html.classList.contains('state-project-detail-open') || html.querySelector('#content article.wadeandleta-project')) return true;

  return false;
}

const checkStudioOpen = () => {
  const html = document.documentElement;

  if (html.classList.contains('state-studio-open') || html.querySelector('#content article.wadeandleta-studio')) return true;

  return false;
}

const checkVisible = (elm, evalType, scrT) => {
  evalType = evalType || "visible";

  var vpH = $(window).height(), // Viewport Height
    y = $(elm).offset().top,
    elementHeight = $(elm).height();

  if (evalType === "visible") return y < vpH + scrT && y > scrT - elementHeight;
  if (evalType === "above") return y < vpH + scrT;
};

class MenuVideo {
  constructor(imageEl, opts, windowSize, isMobile) {
    const options = opts || {};

    this.imageEl = imageEl;
    this.loop_forwards = options.loop_forwards || false;
    this.callbacks = options.callbacks || {};
    this.playing = 0;
    this.loaded = false;
    this.reverse_interval;
    this.windowSize = windowSize;
    this.isMobile = isMobile;

    this.init();
  }
  init() {
    if (this.isMobile) return;
    var that = this;
    const imgEl = this.imageEl.querySelector('img');
    let gifUrl = imgEl.getAttribute('rel:animated_src');
    const expression = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([\-?\.?\w+]*)(:[0-9]{1,5})?(\/.*)?\s(\d+w)$/i;
    const srcset = imgEl.getAttribute('data-srcset');

    function buildUrl(groups) {
      let url = '';
      for (let i = 1; i < 6; i++) {
        if (groups[i]) url += groups[i];
      }
      return url;
    }

    const srcsetList = [];

    if (srcset) {
      srcset.split(',').forEach(url => {
        const groups = expression.exec(url.trim());

        if (groups) {
          srcsetList.push({width: parseInt(groups[6]), url: buildUrl(groups)});
        }
      });

      srcsetList.sort((a, b) => (a.width > b.width) ? 1 : -1)

      let lastUrl;

      srcsetList.forEach(obj => {
        if (obj.width <= this.windowSize[0]) {
          lastUrl = obj.url;
        } else if (!lastUrl) {
          lastUrl = obj.url;
        }
      });

      if (lastUrl) gifUrl = lastUrl;
    }

    this.gif = new SuperGif({
      gif: imgEl,
      progressbar_height: 0,
      loop_mode: this.loop_forwards,
      auto_play: false,
      on_end: () => {
        if (!that.loop_forwards) {
          that.pause();
          that.playReverse();
        }
    }});

    this.gif.load_url(gifUrl, () => {
      that.loaded = true;
      that.imageEl.parentNode.classList.add('loaded');
      that.imageEl.classList.add('loaded');
      that.callbacks.loaded(that);
    });
  }
  isLoaded() {
    return this.loaded;
  }
  play() {
    if (!this.isLoaded()) return;
    clearInterval(this.reverse_interval);
    this.playing = 1;
    this.gif.play();
  }
  playReverse() {
    if (!this.isLoaded()) return;
    const that = this;

    this.playing = -1;
    clearInterval(this.reverse_interval);

    this.reverse_interval = setInterval(() => {
      if (that.playing == -1) {
        if (that.gif.get_current_frame() == 0) {
          that.play();
        } else {
          that.gif.move_relative(-1);
        }
      } else {
        clearInterval(that.reverse_interval);
      }
    }, 40);
  }
  pause() {
    if (!this.isLoaded()) return;
    if (this.playing == 1) {
      this.gif.pause();
      this.playing = 0;
    } else if (this.playing == -1) {
      clearInterval(this.reverse_interval);
      this.playing = 0;
    }
  }
  setTime(time) {
    if (!this.isLoaded()) return;
    this.gif.move_to(time || 0);
  }
  setTimeEnd() {
    if (!this.isLoaded()) return;
    // this.setTime(this.gif.get_length() - 1);
    // Set to the beginning instead of the end
    this.gif.move_to(0);
  }
}

export function menuInit(headerEl, footerObj) {
  const html = document.documentElement;
  const bodyEl = html.querySelector("body");
  const textNavEl = headerEl.querySelector('.nav-wrap');
  const menuEl = headerEl.querySelector(".menu");
  const contactEl = headerEl.querySelector('#header-contact');
  const contentEl = html.querySelector("#content");
  const indexInner = contentEl.querySelector("article.projects-index .index-inner");
  const detailInner = contentEl.querySelector("article.projects-project .index-inner");
  const studioSelector = "article.wadeandleta-studio";
  const homeLinkEl = textNavEl.querySelector('a.home');
  const footerEl = html.querySelector('footer#footer');
  let isMobile = isMobileSite();
  let windowSize = [window.innerWidth, window.innerHeight];

  const projectLinkList = headerEl.querySelectorAll('.nav-wrap a.home, .nav-wrap a.work');
  const studioLinkList = headerEl.querySelectorAll('.nav-wrap a.studio');
  const menuVideoList = {};

  const setupVideos = () => {
    menuVideoList['studio'] = new MenuVideo(menuEl.querySelector('.studio > .gif-wrap'), {
      'loop_forwards': true,
      'callbacks': {
        'loaded': (video) => {
          if (checkStudioOpen()) {
            video.setTime(0);
          } else {
            video.setTimeEnd();
          }
        }
      }
    }, windowSize, isMobile);
    menuVideoList['work'] = new MenuVideo(menuEl.querySelector('.work > .gif-wrap'), {
      'callbacks': {
        'loaded': (video) => {
          if (html.classList.contains("state-projects-open")) {
            video.setTime(0);
          } else {
            video.setTimeEnd();
          }
        }
      }
    }, windowSize, isMobile);
  };

  setupVideos();

  const openProjects = () => {
    html.classList.add('state-projects-open');
    html.classList.remove('state-home-open');
    if (window.openProjects) window.openProjects();
  }

  const closeStudio = () => {
    let page = contentEl.querySelector(studioSelector);

    if (page) {
      page.classList.remove("scroll");
      bodyEl.classList.remove("no-scroll");
      html.classList.add("state-studio-transition-out", 'state-ajax-closing', "state-footer-reset");

      setTimeout(() => {
        contactEl.style.transform = '';
      }, 250);
      setTimeout(function() {
        teardownContainers([page]);
        contentEl.removeChild(page);
        html.classList.remove("state-studio-transition-out", 'state-ajax-closing');

        if (!html.querySelector('#content article.wadeandleta-studio')) {
          html.classList.remove('state-studio-open', 'state-studio-content-visible');
          bodyEl.classList.remove("studio");
          getScrollController().allowScroll('studio');
        }

        pushState(bodyEl.getAttribute("old-url"));
        document.title = bodyEl.getAttribute("old-title");
        updateLinkState([headerEl]);
        if (window.azProjectsList) window.azProjectsList.reset();
        window.footerScroll(window.scrollY, 0);

        setTimeout(() => {
          html.classList.remove("state-footer-reset", 'state-ajax-to-ajax');
        }, 550);
      }, PAGE_TRANSITION_DELAY);
    }
  };

  const studioAjax = new AjaxLoader(
    "wadeandleta",
    function(newBody) {
      const newContent = $(newBody).find(studioSelector);
      $("body").addClass($(newBody).attr("class"));
      bodyEl.classList.remove("no-scroll");
      $(contentEl).append(newContent);
      let page = contentEl.querySelector(studioSelector);
      page.classList.remove("scroll");
      page.classList.add("ajax-load");
      html.classList.add('state-studio-transition-in');
      getScrollController().preventScroll('studio', [page]);

      updateLinkState([headerEl]);

      initContainers([page]);

      setTimeout(function() {
        html.classList.remove("state-studio-transition-in");

        setTimeout(function() {
          bodyEl.classList.add("no-scroll");
          page.classList.add("scroll");
          html.classList.remove('state-ajax-page-loading', 'state-studio-transitioning');

          if (window.azProjectsList) window.azProjectsList.destroy();
        }, PAGE_TRANSITION_DELAY);
      }, 250);
    },
    {
      onBeforeLoad: function(url) {
        document.documentElement.classList.add('state-ajax-page-loading', 'state-studio-transitioning');

        if (checkProjectDetailOpen() || checkStudioOpen()) {
          document.documentElement.classList.add('state-ajax-to-ajax');
        }
      }
    }
  );

  const studioLinkClick = (url, e) => {
    e.stopPropagation();
    e.preventDefault();

    if (html.classList.contains('state-ajax-page-loading')) return false;

    bodyEl.setAttribute("old-url", window.location.pathname);
    bodyEl.setAttribute("old-title", document.title);
    studioAjax.load(url, "none");

    return false;
  }

  if (indexInner) {
    if (indexInner.classList.contains("state-projects-open")) {
      openProjects();
    } else {
      html.classList.add('state-home-open');
    }
  } else if (detailInner) {
    if (detailInner.classList.contains("state-projects-open")) {
      openProjects();
    }
  }

  const BreakException = {};

  const resetArtworks = () => {
    html.classList.add('state-home-open');
    html.classList.remove("state-projects-open");

    if (indexInner) {
      try {
        indexInner.querySelectorAll('.artwork-list .artwork-listing').forEach(el => {
          if (checkVisible(el, "visible", window.scrollY)) {
            const scrollToY = el.offsetTop - contactEl.offsetTop;
            zenscroll.toY(scrollToY, getScrollSpeed(window.scrollY, scrollToY));

            throw BreakException;
          };
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    }
  }

  const resetProjects = (e, wrapperEl, videoObj) => {
    openProjects();
    if (videoObj) videoObj.setTimeEnd();

    if (indexInner) {
      if (wrapperEl) {
        pushState(wrapperEl.getAttribute("href"));
        updateLinkState([headerEl]);
      }
      indexInner.classList.add("state-projects-open");
      if (e) e.preventDefault();

      const projectList = indexInner.querySelectorAll('.project-list .project-listing');

      if (projectList) {
        let scrollToY;
        const lastProjectEl = projectList[projectList.length - 1];

        if (window.scrollY > lastProjectEl.offsetTop + lastProjectEl.offsetHeight) {
          scrollToY = footerEl.offsetTop - contactEl.offsetTop + 1;
        } else {
          let selectedProjectEl = windowSize[1] / 2 > window.scrollY ? projectList[0] : lastProjectEl;

          try {
            projectList.forEach(projectEl => {
              if (checkVisible(projectEl, "visible", window.scrollY)) {
                selectedProjectEl = projectEl;
                throw BreakException;
              };
            });
          } catch (e) {
            if (e !== BreakException) throw e;
          }
          scrollToY = selectedProjectEl.offsetTop - contactEl.offsetTop;
        }

        zenscroll.toY(scrollToY, getScrollSpeed(window.scrollY, scrollToY));

        setTimeout(() => {
          html.classList.add('state-projects-settled');

          setTimeout(() => {
            html.classList.remove('state-home-to-work');
          }, 500);
        }, getScrollSpeed(window.scrollY, scrollToY) + 250);
      }

      return false;
    }
  };

  studioLinkList.forEach(linkEl => {
    const videoLinkEl = menuEl.querySelector('a.studio');
    const videoObj = menuVideoList['studio'];
    let enterTimeout;

    linkEl.addEventListener("mouseenter", () => {
      enterTimeout = setTimeout(() => {
        videoObj.play();
        html.classList.add("state-menu-hover", "state-menu-hover-studio");
      }, 350);
    });
    linkEl.addEventListener("mouseleave", () => {
      clearTimeout(enterTimeout);
      html.classList.remove("state-menu-hover", "state-menu-hover-studio");

      videoObj.pause();

      if (html.classList.contains("state-projects-open")) {
        videoObj.setTimeEnd();
      } else {
        videoObj.setTime(0);
      }
    });
    linkEl.addEventListener("click", e => {
      html.classList.remove('state-home-to-work', 'state-projects-settled')
      videoObj.pause();

      if (checkStudioOpen()) {
        if (indexInner || detailInner) {
          e.stopPropagation();
          e.preventDefault();

          if (html.classList.contains("state-projects-open")) {
            setTimeout(function() {
              resetProjects(null, linkEl, videoObj);
            }, PAGE_TRANSITION_DELAY);
          }
          closeStudio();
        } else {
          window.location = menuEl.querySelector("a.work").getAttribute("href");
        }
        return false;
      } else if (checkProjectDetailOpen()) {
        e.preventDefault();
        e.stopPropagation();
        studioLinkClick(linkEl.getAttribute("href"), e);

        setTimeout(() => {
          window.closeProjectDetail();
        }, PAGE_TRANSITION_DELAY * 2);

        return false;
      } else {
        studioLinkClick(linkEl.getAttribute("href"), e);
      }
    });
  });

  projectLinkList.forEach(linkEl => {
    const videoLinkEl = menuEl.querySelector('a.work');
    const videoObj = menuVideoList['work'];
    let enterTimeout;

    linkEl.addEventListener("mouseenter", () => {
      enterTimeout = setTimeout(() => {
        videoObj.play();
        html.classList.add("state-menu-hover", linkEl.classList.contains('home') ? "state-menu-hover-home" : "state-menu-hover-projects");
      }, 350);
    });
    linkEl.addEventListener("mouseleave", () => {
      clearTimeout(enterTimeout);
      html.classList.remove("state-menu-hover", "state-menu-hover-home", "state-menu-hover-projects");

      videoObj.pause();

      if (html.classList.contains("state-projects-open")) {
        videoObj.setTimeEnd();
      } else {
        videoObj.setTime(0);
      }
    });

    linkEl.addEventListener("click", e => {
      html.classList.remove('state-home-to-work', 'state-projects-settled')
      videoObj.pause();

      if (indexInner) {
        const isPageOpen = checkStudioOpen() || checkProjectDetailOpen();

        // Home button clicked
        if (linkEl.classList.contains('home')) {
          if (isPageOpen) {
            if (!html.classList.contains("state-home-open")) {
              html.classList.add('state-home-open');
              html.classList.remove('state-projects-open');
            }

            closeStudio();
            window.closeProjectDetail(true);
          }

          setTimeout(() => {
            pushState("/");
            resetArtworks();
            updateLinkState([headerEl]);
          }, isPageOpen ? PAGE_TRANSITION_DELAY : 0);
        } else {
          if (isPageOpen) {
            if (!html.classList.contains("state-projects-open")) {
              html.classList.add('state-projects-open');
              html.classList.remove('state-home-open');
            }

            closeStudio();
            window.closeProjectDetail(true);

            setTimeout(() => {
              if (window.azProjectsList) window.azProjectsList.reset();
              pushState(linkEl.getAttribute('href'));
              resetProjects(null, linkEl, videoObj);
              updateLinkState([headerEl]);
            }, PAGE_TRANSITION_DELAY);
          } else {
            if (html.classList.contains("state-projects-open")) {
              const projectList = indexInner.querySelectorAll('.project-list .project-listing');
              if (window.azProjectsList) window.azProjectsList.reset();

              if (projectList) {
                const scrollToY = projectList[0].offsetTop - contactEl.offsetTop;
                zenscroll.toY(scrollToY, getScrollSpeed(window.scrollY, scrollToY));
              }
            } else {
              if (html.classList.contains('state-home-open')) html.classList.add('state-home-to-work');
              if (window.azProjectsList) window.azProjectsList.reset();
              pushState(linkEl.getAttribute('href'));
              resetProjects(null, null, videoObj);
            }

            updateLinkState([headerEl]);
          }
        }

        e.preventDefault();
        return false;
      }
    });
  });

  setInterval(() => {
    homeLinkEl.classList[homeLinkEl.classList.contains('flipped') ? 'remove' : 'add']('flipped');
  }, 4000);

  return {
    resize: (...args) => {
      isMobile = isMobileSite();
      windowSize = [args[0], args[1]];
    },
    scroll: (...args) => {
    }
  }
}
