import 'c/util/scroll_preventer.scss'

import { addEventListener, removeEventListener } from 'j/util/events'
import { forEach } from 'j/util/traversing'

function emptyObject (obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) { return false }
  }
  return true
}

/**
 * @class ScrollPreventer
 *
 * Controls the ability of the user to scroll the page.
 *
 * Preventing scroll on the body element is a bit complex. This module:
 * 1. Sets a class on the html element (and therefore requires use of
 *    scrolling.scss) which sets overflow on the html element etc such that
 *    scroll is prevented on desktop.
 * 2. Conditionally prevents touchmove to ensure scroll is prevented on mobile
 *    devices. See docstrings for constructor and preventScroll. Not that this
 *    requires use of a non-passive event listener.
 * 3. Prevents an overscroll related bug on iOS, see preventbodyBounce
 *    docstring.
 *
 * This module avoids the body scroll position changing (as would happen if the
 * html element was set to position: fixed, a common approach to solving this
 * problem).
 *
 * Upon calling preventScroll, scroll will be prevented until allowScroll
 * has been called passing in all keys that were provided to preventScroll,
 * thus it is possible to prevent scroll for multiple reasons and have scroll
 * continue to be prevented until all of those reasons have ceased to exist.
 *
 * PB TODO: Finish extending this so it functions on any element (eg.
 * prevent-scroll class is not always set on body).
 */
class ScrollPreventer {
  /**
   * Constructs an instance of ScrollPreventer.
   *
   * Some mobile devices (primarily iOS) ignore overflow hidden on the html
   * element, so this constructor assigns a handler that prevents default for
   * all touchmove events on body if scroll prevention has been requested.
   * Any element that the user is permitted to scroll must be passed to
   * preventScroll.
   *
   * @constructs ScrollPreventer
   */
  constructor () {
    this.preventions = {}
    this.active = false

    addEventListener(document.body, 'touchmove', this.preventTouch, {
      passive: false
    })
  }

  /**
   * Event handler that simply prevents default if this is active.
   *
   * @method ScrollPreventer#preventTouch
   * @param {Event} e
   */
  preventTouch (e) {
    if (this.active) {
      e.preventDefault()
    }
  }

  /**
   * Event handler that simply stops propogation. Abstracted into its own
   * method so the handler can be added and removed easily.
   *
   * @method ScrollPreventer#preventBubble
   * @param {Event} e
   */
  preventBubble (e) {
    e.stopPropagation()
  }

  /**
   * iOS has a bug where overscroll will be applied to the body instead of the
   * permitted element if the permitted element is at scroll position 0 or
   * scrollHeight. We prevent this by using this scroll handler to
   * ensure that the permitted element can only be scrolled between 1 and its
   * scrollHeight - 1.
   *
   * @method ScrollPreventer#preventBodyBounce
   * @param {Event} e
   */
  preventBodyBounce (e) {
    const target = e.target || e.srcElement
    if (target.scrollTop <= 0) {
      target.scrollTo(0, 1)
    } else if (target >= target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight - 1)
    }
  }

  /**
   * Requests that scroll be prevented until allowScroll is called passing in a
   * matching key.
   *
   * Any elements that are permitted to scroll during this time (eg. an
   * overlay) must be passed to this method and will have a touchmove handler
   * assigned which stops propogation, thus the handler on body that prevents
   * touchmove will never be called.
   *
   * @method ScrollPreventer#preventScroll
   * @param {string} key
   * @param {(Array.<HtmlElement>|HtmlElement)} scrollableEls
   */
  preventScroll (key, scrollableEls = []) {
    forEach(scrollableEls, scrollableEl => {
      addEventListener(scrollableEl, 'touchmove', this.preventBubble, {
        passive: true
      })
      addEventListener(scrollableEl, 'scroll', this.preventBodyBounce, {
        passive: true
      })
      this.preventBodyBounce({
        target: scrollableEl
      })
    })
    this.preventions[key] = scrollableEls
    this.active = true
    document.documentElement.classList.add('disable-scroll')
  }

  /**
   * Removes the given key from the list of preventions and removes handlers
   * from any permitted elements, then reenables scroll if no other keys have
   * requested it.
   *
   * @method ScrollPreventer#allowScroll
   * @param {string} key
   */
  allowScroll (key) {
    forEach(this.preventions[key], scrollableEl => {
      removeEventListener(scrollableEl, 'touchmove', this.preventBubble, {
        passive: true
      })
      removeEventListener(scrollableEl, 'scroll', this.preventBodyBounce, {
        passive: true
      })
    })
    delete this.preventions[key]
    this.active = !emptyObject(this.preventions)
    if (!this.active) {
      document.documentElement.classList.remove('disable-scroll')
    }
  }

  teardown (els) {
    removeEventListener(document.body, 'touchmove', this.preventTouch, {
      passive: false
    })
  }
}

const scrollPreventer = new ScrollPreventer()
export default scrollPreventer
