import { register } from '../util/init'
import { isMobileSite } from './constants'
import { Dot } from './paper'

import { VideoPlayer } from '../../videoplayer/videoplayer'

function buildUrl (groups) {
  let url = ''
  for (let i = 1; i < 5; i++) {
    if (groups[i]) url += groups[i]
  }
  return url
}

function getSrcSet (imgEl, windowSize) {
  const srcset = imgEl.getAttribute('srcset') || imgEl.getAttribute('data-srcset')
  const expression = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([\-?\.?\w+]*)(:[0-9]{1,5})?(\/.*)?\s(\d+w)$/i
  const srcsetList = []

  if (srcset) {
    srcset.split(',').forEach(url => {
      const groups = expression.exec(url.trim())

      if (groups) {
        srcsetList.push({ width: parseInt(groups[5]), url: buildUrl(groups) })
      }
    })

    srcsetList.sort((a, b) => (a.width > b.width) ? 1 : -1)

    let lastUrl

    srcsetList.forEach(obj => {
      if (obj.width <= windowSize[0]) {
        lastUrl = obj.url
      } else if (!lastUrl) {
        lastUrl = obj.url
      }
    })

    if (lastUrl) return lastUrl
  }

  return false
}

function windowScroll (el) {
  var doc = el
  return {
    left: (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
    top: (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
  }
}

class PaperPerson {
  constructor (container, pixelRatio, windowSize, isMobile) {
    this.container = container
    this.canvasWrapEl = container.querySelector('.canvas-wrap')
    this.canvasEl
    this.scope
    this.pixelRatio = pixelRatio
    this.dotList = []

    this.canvasSize = [0, 0]
    this.windowSize = windowSize
    this.isMobile = isMobile

    this.init()
  }

  init () {
    this.id = this.container.getAttribute('data-id') || ''
    this.contentText = this.container.getAttribute('data-text') || ''

    this.initImage()
  }

  initImage () {
    const that = this
    const imgEl = this.container.querySelector('.image-wrap img')

    if (!imgEl.getAttribute('src')) {
      imgEl.setAttribute('src', getSrcSet(imgEl, this.windowSize))

      imgEl.onload = (e) => {
        that.initCanvas()
      }
    } else {
      this.initCanvas()
    }
  }

  initCanvas () {
    this.canvasEl = document.createElement('canvas')
    this.canvasEl.setAttribute('resize', '')
    this.canvasWrapEl.children[0].appendChild(this.canvasEl)

    this.scope = new paper.PaperScope()
    this.scope.setup(this.canvasEl)

    const textLayer = new this.scope.Layer({
      children: [],
      clipped: false,
      fillColor: new this.scope.Color(0, 0, 0)
    })

    this.canvasSize = [this.canvasEl.width * this.pixelRatio, this.canvasEl.height * this.pixelRatio]

    const point = new this.scope.Point(this.canvasSize[0] / 2, this.canvasSize[1] / 2)
    const dot = new Dot(this.scope, point, 8, this.canvasSize[0] * 0.278, 10)
    const textDot = new Dot(this.scope, point, 8, this.canvasSize[0] * 0.278 * 1.15, 10, this.getText(), textLayer, this.getStyle())
    const raster = new this.scope.Raster('person-' + this.id)
    const bounds = this.scope.project.view.bounds.clone()

    bounds.width = bounds.width
    const newWidth = bounds.width * (this.isMobile ? 2 : 1.5)

    if (raster.width > raster.height) {
      raster.height = raster.height / raster.width * newWidth / this.pixelRatio
      raster.width = newWidth / this.pixelRatio
    } else {
      raster.height = raster.height / raster.width * newWidth
      raster.width = newWidth
    }

    raster.position = dot.center

    const imageLayer = new this.scope.Layer({
      children: [dot.path, raster],
      clipped: true
    })

    imageLayer.scale(this.pixelRatio)

    this.scope.project.addLayer(new this.scope.Layer({ children: [textDot.path] }))
    this.scope.project.addLayer(imageLayer)
    this.scope.project.addLayer(textLayer)

    textDot.resetPoints(false)
    dot.resetPoints(false)

    this.dotList.push(dot)
    this.dotList.push(textDot)

    dot.path.onFrame = (e) => { dot.frame(e) }
    textDot.path.onFrame = (e) => { textDot.frame(e) }
  }

  getText () {
    return this.contentText.split('').reverse().join('')
  }

  getStyle () {
    if (!this.scope) return {}

    return {
      fontColor: new this.scope.Color(0, 0, 0),
      fontSize: this.isMobile ? 10 : 22,
      fontFamily: 'Sohne',
      fontWeight: 400
    }
  }

  animate () {
    if (!this.scope) return

    this.scope.view.draw()
    this.scope.project.layers.forEach(layer => {
      layer.visible = false
      layer.visible = true
    })
  }

  scroll (scrT) {
    if (!this.scope) return

    if (this.scope.view.isVisible()) {
      this.scope.view.play()
    } else {
      this.scope.view.pause()
    }
  }

  destroy () {
    if (this.scope) this.scope.remove()
    if (this.canvasEl) this.canvasEl.remove()
    this.scope = null
    this.canvasEl = null
  }
}

class Slider {
  constructor (container) {
    this.container = container

    this.index = 0
    this.blockList = container.querySelectorAll('.block')

    this.init()
  }

  init () {
    this.viewEl = document.createElement('div')
    this.viewEl.classList.add('view-wrap')
    this.container.appendChild(this.viewEl)

    this.initControls()

    this.resize()
    this.update()
  }

  initControls () {
    const that = this

    if (this.container.querySelector('.controls-wrap')) this.container.querySelector('.controls-wrap').remove()

    this.controlWrapEl = document.createElement('div')
    this.controlWrapEl.classList.add('control-wrap')

    const leftEl = document.createElement('div')
    const rightEl = document.createElement('div')

    leftEl.classList.add('left')
    rightEl.classList.add('right')

    leftEl.textContent = '←'
    rightEl.textContent = '→'

    this.controlWrapEl.appendChild(leftEl)
    this.controlWrapEl.appendChild(rightEl)

    this.container.appendChild(this.controlWrapEl)

    leftEl.addEventListener('click', e => {
      that.previous()
    })

    rightEl.addEventListener('click', e => {
      that.next()
    })
  }

  previous () {
    if (this.index > 0) {
      this.index -= 1
    } else {
      this.index = this.blockList.length - 1
    }

    this.update()
  }

  next () {
    if (this.index < this.blockList.length - 1) {
      this.index += 1
    } else {
      this.index = 0
    }

    this.update()
  }

  update () {
    this.viewEl.innerHTML = this.blockList[this.index].innerHTML
  }

  resize () {
    let h = 0

    this.blockList.forEach(el => {
      h = Math.max(h, el.offsetHeight)
    })

    this.viewEl.style.height = `${h}px`
  }
}

// function studioIndex (container) {
//   const html = document.documentElement
//   const headerContactEl = html.querySelector('#header-contact')
//   const headerContactTextEl = headerContactEl.querySelector('.text-wrap')
//   const menuEl = document.querySelector('header#header .menu')
//   const menuButtonEl = menuEl.querySelector('.studio .gif-wrap')
//   const pageTitleWrapEl = container.querySelector('.page-title-wrap')
//   const peopleList = container.querySelectorAll('.person-list .person-listing')
//   const textWrap = container.querySelector('.speaking-wrap .visible-wrap')
//   const logItems = container.querySelectorAll('.speaking-wrap .slide-listing')
//   const btns = container.querySelectorAll('.speaking-wrap .controls-wrap span')
//   const studioInnerEl = container.querySelector('.studio-inner')
//   const pixelRatio = 1 / window.devicePixelRatio
//   const dots = []
//   let paperList = []
//   let windowSize = [window.innerWidth, window.innerHeight]
//   let isMobile = isMobileSite()
//   let speakingLogIndex = 0
//   let containerH = container.offsetHeight
//   let headerContactH = headerContactTextEl.offsetWidth
//   let studioInnerTop = studioInnerEl.offsetTop

//   const setupPeople = () => {
//     peopleList.forEach((personEl) => {
//       paperList.push(new PaperPerson(personEl, pixelRatio, windowSize, isMobile))
//     })
//   }

//   const animatePeople = () => {
//     paperList.forEach(person => {
//       person.animate()
//     })
//   }

//   const resetPeople = () => {
//     paperList.forEach(person => {
//       person.destroy()
//     })

//     paperList = []

//     setupPeople()
//   }

//   function updateLog (i) {
//     textWrap.parentNode.classList[i == 0 ? 'remove' : 'add']('has-previous')
//     textWrap.parentNode.classList[i == logItems.length - 1 ? 'remove' : 'add']('has-next')

//     while (textWrap.firstChild) {
//       textWrap.removeChild(textWrap.firstChild)
//     }
//     textWrap.appendChild(logItems[i].cloneNode(true))
//     resizeLog()
//   }

//   const resizeLog = () => {
//     let h = 0
//     logItems.forEach((el) => {
//       if (h < el.clientHeight) h = el.clientHeight
//     })
//     textWrap.style.height = h + 'px'
//   }

//   html.classList.add('state-studio-open')

//   const studioScroll = (scrT) => {
//     paperList.forEach(person => {
//       person.scroll(scrT)
//     })

//     if (headerContactH > studioInnerTop) {
//       headerContactEl.style.transform = `translateY(-${scrT}px) rotate(90deg)`
//     } else {
//       headerContactEl.style.transform = `translateY(-${Math.max(0, (scrT + containerH) - studioInnerTop + Math.min(0, headerContactH - containerH))}px) rotate(90deg)`
//     }

//     if (isMobile) {
//       html.classList[(studioInnerTop <= scrT + 80) ? 'add' : 'remove']('state-studio-content-visible')
//     }
//   }

//   container.addEventListener('scroll', e => {
//     const scrollY = Math.max(0, container.scrollTop)

//     studioScroll(scrollY)
//   })

//   let pageResizeTimer

//   let sliderList = []

//   if (isMobile) {
//     container.querySelectorAll('.clients-wrap, .services-wrap, .speaking-wrap').forEach(el => {
//       sliderList.push(new Slider(el))
//     })
//   } else {
//     btns.forEach(function (el) {
//       el.addEventListener('click', function () {
//         if (el.classList.contains('left')) {
//           if (speakingLogIndex > 0) {
//             speakingLogIndex -= 1
//           }
//         } else if (el.classList.contains('right')) {
//           if (speakingLogIndex < logItems.length - 1) {
//             speakingLogIndex += 1
//           }
//         }
//         updateLog(speakingLogIndex)
//       })
//     })

//     updateLog(speakingLogIndex)
//   }

//   setupPeople()
//   animatePeople()

//   return {
//     resize: (...args) => {
//       clearTimeout(pageResizeTimer)
//       windowSize = args
//       isMobile = isMobileSite()
//       containerH = container.offsetHeight
//       studioInnerTop = studioInnerEl.offsetTop
//       headerContactH = headerContactTextEl.offsetWidth

//       if (isMobile) {
//         sliderList.forEach(obj => {
//           obj.resize()
//         })
//       } else {
//         resizeLog()
//       }

//       pageResizeTimer = setTimeout(() => {
//         resetPeople()
//       }, 250)

//       if (isMobile) {
//         container.style.height = `${windowSize[1]}px`
//       } else {
//         container.style.height = ''
//       }
//     },
//     teardown: () => {
//       Array.from(paperList).forEach(person => {
//         person.destroy()
//       })

//       paperList = []
//     }
//   }
// }
// register('.wadeandleta-studio', studioIndex)

function studioIndex (container) {
  const html = document.documentElement
  const headerContactEl = html.querySelector('#header-contact')
  const headerContactTextEl = headerContactEl.querySelector('.text-wrap')
  const peopleList = container.querySelectorAll('.person-list .person-listing')
  const textWrap = container.querySelector('.speaking-wrap .visible-wrap')
  const logItems = container.querySelectorAll('.speaking-wrap .slide-listing')
  const btns = container.querySelectorAll('.speaking-wrap .controls-wrap span')
  const studioInnerEl = container.querySelector('.studio-inner')
  const pixelRatio = 1 / window.devicePixelRatio
  const studioVideoList = []

  let paperList = []
  let windowSize = [window.innerWidth, window.innerHeight]
  let isMobile = isMobileSite()
  let speakingLogIndex = 0
  let containerH = container.offsetHeight
  let headerContactH = headerContactTextEl.offsetWidth
  let studioInnerTop = studioInnerEl.offsetTop

  const setupPeople = () => {
    peopleList.forEach((personEl) => {
      paperList.push(new PaperPerson(personEl, pixelRatio, windowSize, isMobile))
    })
  }

  const animatePeople = () => {
    paperList.forEach(person => {
      person.animate()
    })
  }

  const resetPeople = () => {
    paperList.forEach(person => {
      person.destroy()
    })

    paperList = []

    setupPeople()
  }

  function updateLog (i) {
    textWrap.parentNode.classList[i == 0 ? 'remove' : 'add']('has-previous')
    textWrap.parentNode.classList[i == logItems.length - 1 ? 'remove' : 'add']('has-next')

    while (textWrap.firstChild) {
      textWrap.removeChild(textWrap.firstChild)
    }
    textWrap.appendChild(logItems[i].cloneNode(true))
    resizeLog()
  }

  const resizeLog = () => {
    let h = 0
    logItems.forEach((el) => {
      if (h < el.clientHeight) h = el.clientHeight
    })
    textWrap.style.height = h + 'px'
  }

  html.classList.add('state-studio-open')

  const studioVideoInitOrLoad = (el, i) => {
    const videoEl = el.querySelector('.video-player')

    if (!videoEl) return

    if (i in studioVideoList) {
      studioVideoList[i].loadVideo()
    } else {
      studioVideoList.push(videoEl ? new VideoPlayer(videoEl) : {})
    }
  }

  const studioScroll = (scrT) => {
    paperList.forEach(person => {
      person.scroll(scrT)
    })

    if (headerContactH > studioInnerTop) {
      headerContactEl.style.transform = `translateY(-${scrT}px) rotate(90deg)`
    } else {
      headerContactEl.style.transform = `translateY(-${Math.max(0, (scrT + containerH) - studioInnerTop + Math.min(0, headerContactH - containerH))}px) rotate(90deg)`
    }

    if (isMobile) {
      html.classList[(studioInnerTop <= scrT + 80) ? 'add' : 'remove']('state-studio-content-visible')
    }
  }

  container.addEventListener('scroll', e => {
    const scrollY = Math.max(0, container.scrollTop)

    studioScroll(scrollY)
  })

  let pageResizeTimer

  let sliderList = []

  if (isMobile) {
    container.querySelectorAll('.press-wrap, .clients-wrap, .services-wrap, .speaking-wrap').forEach(el => {
      sliderList.push(new Slider(el))
    })
  }
  // else {
  //   btns.forEach(function (el) {
  //     el.addEventListener('click', function () {
  //       if (el.classList.contains('left')) {
  //         if (speakingLogIndex > 0) {
  //           speakingLogIndex -= 1
  //         }
  //       } else if (el.classList.contains('right')) {
  //         if (speakingLogIndex < logItems.length - 1) {
  //           speakingLogIndex += 1
  //         }
  //       }
  //       updateLog(speakingLogIndex)
  //     })
  //   })

  //   updateLog(speakingLogIndex)
  // }

  // setupPeople()
  // animatePeople()

  peopleList.forEach((el, i) => {
    studioVideoInitOrLoad(el)
  })

  return {
    resize: (...args) => {
      clearTimeout(pageResizeTimer)
      windowSize = args
      isMobile = isMobileSite()
      containerH = container.offsetHeight
      studioInnerTop = studioInnerEl.offsetTop
      headerContactH = headerContactTextEl.offsetWidth

      if (isMobile) {
        sliderList.forEach(obj => {
          obj.resize()
        })
      } else {
        // resizeLog()
      }

      // pageResizeTimer = setTimeout(() => {
      //   resetPeople()
      // }, 250)

      if (isMobile) {
        container.style.height = `${windowSize[1]}px`
      } else {
        container.style.height = ''
      }
    },
    teardown: () => {
      // Array.from(paperList).forEach(person => {
      //   person.destroy()
      // })

      paperList = []
    }
  }
}
register('.wadeandleta-studio', studioIndex)
