import docReady from "doc-ready";
import paper from "paper";

import { initContainers } from "../util/init";
import { updateLinkState } from "../util/link_state";
import { addHandler } from "../util/scroll_resize.js";
import scrollPreventer from "../util/scroll_preventer.js";

import { menuInit } from "./menu";
import { footerInit } from "./footer";

const html = document.documentElement;

let scrollController
export const getScrollController = () => scrollController

function initContent(container) {
  initContainers(container);
  updateLinkState(html.querySelectorAll("#header, #footer, #content"));
}

function setup() {
  const scrollbarCheck1El = html.querySelector('.scrollbar-check-base');
  const scrollbarCheck2El = html.querySelector('.scrollbar-check-inner');
  let hasCheckedScrollbars = false;
  let resizeTimer;

  scrollController = scrollPreventer

  paper.install(window);

  const footerObj = footerInit(html.querySelector("footer#footer"));
  const menuObj = menuInit(html.querySelector("header#header"), footerObj);

  window.footerObj = footerObj;

  // runs every time the window location changes
  updateLinkState(document.querySelectorAll("#header, #footer"));


  addHandler({
    resize: (...args) => {
      clearTimeout(resizeTimer);
      menuObj.resize.apply(menuObj, args);
      footerObj.resize.apply(footerObj, args);

      html.classList.add('state-page-resizing');

      resizeTimer = setTimeout(() => {
        html.classList.remove('state-page-resizing');
      }, 250);

      if (!hasCheckedScrollbars) {
        if (scrollbarCheck1El.offsetWidth != scrollbarCheck2El.offsetWidth) {
          hasCheckedScrollbars = true;
          html.classList.add('state-scrollbars-fixed');
        }
      }
    },
    scroll: (...args) => {
      menuObj.scroll.apply(menuObj, args);
      footerObj.scroll.apply(footerObj, args);
    }
  });
}

docReady(() => {
  setup();
  initContent(html.querySelectorAll('#content > article'));

  document.body.classList.add("state-initialised");

  window.onpopstate = function(e) {
    if (e.state === null) {
      window.location.reload();
      return false;
    }
  };
});
